// 
// general.scss
//

html {
  position: relative;
  min-height: 100%;
}

body {
  padding-bottom: 60px;
  overflow-x: hidden;
}
.Toastify__toast {
  padding: 0px !important;
}

.toast-primary .toast-header {
  color: #fff;
  background-color: #5369f8;
  border-bottom: 1px solid #4254c6
}
.toast-primary .toast-body {
  color: #fff;
  background-color: #5369f8;
}

.toast-secondary .toast-header {
  color: #fff;
  background-color: #1e2139;
  border-bottom: 1px solid #181a2d
}
.toast-secondary .toast-body {
  color: #fff;
  background-color: #1e2139;
}

.toast-success .toast-header {
  color: #fff;
  background-color: #43d39e;
  border-bottom: 1px solid #35a87e
}
.toast-success .toast-body {
  color: #fff;
  background-color: #43d39e;
}

.toast-danger .toast-header {
  color: #fff;
  background-color: #ff5c75;
  border-bottom: 1px solid #cc495d
}
.toast-danger .toast-body {
  color: #fff;
  background-color: #ff5c75;
}

.toast-warning .toast-header {
  color: #212529;
  background-color: #ffbe0b;
  border-bottom: 1px solid #CC8400
}
.toast-warning .toast-body {
  color: #212529;
  background-color: #ffbe0b;
}

.toast-info .toast-header {
  color: #fff;
  background-color: #25c2e3;
  border-bottom: 1px solid #1d9bb5
}
.toast-info .toast-body {
  color: #fff;
  background-color: #25c2e3;
}